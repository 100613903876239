import React from 'react';
import {withRouter} from 'react-router-dom';
import s from "./unsubscribeSuccessful.module.css";
import logo_img from "./elly_logo.svg";
import unsubscribe_img from "./unsubscribe.png";
import unsubscribe from "../../WebApi/unsubscribe";

class UnsubscribeSuccessful extends React.Component {

    constructor(props) {
        super(props);
        var language = window.navigator.userLanguage || window.navigator.language;
        var token= '';

        if (window.location.search !== '' && window.location.search.indexOf('?') >= 0) {
            var x = window.location.search
                .split('?')[1]
                .split('&')
                .map(x => {
                    var p = x.split('=');
                    var r = {};
                    r[unescape(p.shift())] = unescape(p.join('='));
                    return r;
                }).reduce(function (result, pair) {
                    return {...result, ...pair}
                }, {});
            if ('$desktop_url' in x) {
                delete x['$desktop_url'];
                window.location.search = Object.keys(x).reduce(function (a, k) {
                    a.push(k + '=' + encodeURIComponent(x[k]));
                    return a
                }, []).join('&');
            }
            if('lang' in x){
                language = x['lang'];
            }
            if('token' in x)
                token = x['token'];
        }

        this.state = {
            labels: {},
            'token': token,
            loading: true
        };

        var feature = window.location.pathname;
        feature += '/';
        feature = feature.replace('//', '/');

        var TRANSLATIONS = {
            'default': {
                'en': {
                    'txtSubject': 'You have successfully unsubscribed',
                    'txtDescription': 'If you change your mind, you can re-subscribe by turning Status updates in the app settings menu on.'
                },
                'sl': {
                    'txtSubject': 'Odjava je bila uspešna',
                    'txtDescription': 'Če si premisliš, se lahko ponovno prijaviš v meniju nastavitev v aplikaciji, in sicer z aktivacijo gumba E-poštna sporočila s posodobitvami stanja.'
                }
            }
        };
        var languages = (feature in TRANSLATIONS) ? TRANSLATIONS[feature] : TRANSLATIONS['default'];
        this.state.labels = (language in languages) ? languages[language] : languages['en'];
        this.state.year = new Date().getFullYear();
    }

    async componentDidMount() {
        this.setState({'loading': true});
        try {
            await unsubscribe.unsubscribe(this.state.token);
            this.setState({
                loading: false
            });
        }catch (e) {
            this.setState({
                loading: false,
                labels: {
                    'txtSubject': 'Error occurred',
                    'txtDescription': 'Please try again later'
                }
            })
        }
    };

    render() {

        return (
            <div className={s.body}>
                <div className="upper-content">
                    <a href="https://www.elly.com" className={s.upperLinkLeft}>
                        <img src={logo_img} alt="Logo" className={s.upperImage}/>
                    </a>
                    <a href="https://www.elly.com" className={s.upperLinkRight}>elly.com</a>
                </div>

                <div className={s.middleContent}>
                    {!this.state.loading && <div className={s.middleChild}>
                        <div className={s.middleImage} >
                            <img src={unsubscribe_img}/>
                        </div>
                        <div id="txtSubject" className={s.subject}>{this.state.labels.txtSubject}</div>
                        <div id="txtDescription" className={s.description}>{this.state.labels.txtDescription}</div>
                    </div>}
                    {this.state.loading && <div className={s.middleChild}>
                        <div className="d-flex justify-content-center align-items-center">
                            <div className={s.ldsRing}>
                                <div/>
                                <div/>
                                <div/>
                                <div/>
                            </div>
                        </div>
                        <div id="txtSubject" className={s.subject}>Unsubscribing ...</div>
                    </div>
                    }
                </div>
                <div className={s.footer}>
                    <div className={s.footerText}>Eligma Ltd. © {this.state.year}</div>
                </div>
            </div>
        );
    }
}

export default withRouter((UnsubscribeSuccessful));
