import React from 'react';
import {withRouter} from 'react-router-dom';
import s from "./wrongDevice.module.css";
import logo_img from "./elly_logo.svg";
import device_img from "./device_img.svg";
import {routes} from "../../AppRouter";

class WrongDevice extends React.Component {

    constructor(props) {
        super(props);
        var language = window.navigator.userLanguage || window.navigator.language;

        if (window.location.search !== '' && window.location.search.indexOf('?') >= 0) {
            var x = window.location.search
                .split('?')[1]
                .split('&')
                .map(x => {
                    var p = x.split('=');
                    var r = {};
                    r[unescape(p.shift())] = unescape(p.join('='));
                    return r;
                }).reduce(function (result, pair) {
                    return {...result, ...pair}
                }, {});
            if ('$desktop_url' in x) {
                delete x['$desktop_url'];
                window.location.search = Object.keys(x).reduce(function (a, k) {
                    a.push(k + '=' + encodeURIComponent(x[k]));
                    return a
                }, []).join('&');
            }
            if('lang' in x){
                language = x['lang'];
            }
        }

        this.state = {
            labels: {}
        };

        var feature = window.location.pathname;
        feature += '/';
        feature = feature.replace('//', '/');

        var TRANSLATIONS = {
            [routes.kycWrongDevice.path]: {
                'en': {
                    'txtSubject': 'Whoops, wrong device',
                    'txtDescription': ''
                },
                'sl': {
                    'txtSubject': 'Brez mobilca ne bo šlo',
                    'txtDescription': ''
                }
            },
            [routes.registrationWrongDevice.path]: {
                'en': {
                    'txtSubject': 'Whoops, wrong device',
                    'txtDescription': 'Use mobile to complete account activation'
                },
                'sl': {
                    'txtSubject': 'Brez mobilca ne bo šlo',
                    'txtDescription': 'Aktivacija računa je možna le s telefonom'
                }
            },
            [routes.passwordWrongDevice.path]: {
                'en': {
                    'txtSubject': 'Whoops, wrong device',
                    'txtDescription': 'Use mobile to complete password reset'
                },
                'sl': {
                    'txtSubject': 'Brez mobilca ne bo šlo',
                    'txtDescription': 'Ponastavitev gesla je možna le s telefonom'
                }
            },
            'default': {
                'en': {
                    'txtSubject': 'Whoops, wrong device',
                    'txtDescription': ''
                },
                'sl': {
                    'txtSubject': 'Brez mobilca ne bo šlo',
                    'txtDescription': ''
                }
            }
        };
        var languages = (feature in TRANSLATIONS) ? TRANSLATIONS[feature] : TRANSLATIONS['default'];
        this.state.labels = (language in languages) ? languages[language] : languages['en'];
    }

    componentDidMount() {
    };

    render() {

        return (
            <div className={s.body}>
                <div className={s.upperContent}>
                    <a href="https://www.elly.com" className={s.upperLinkLeft}>
                        <img src={logo_img} alt="Logo" className={s.upperImage}/>
                    </a>
                    <a href="https://www.elly.com" className={s.upperLinkRight}>elly.com</a>
                </div>

                <div className={s.middleContent}>
                    <div className={s.middleChild}>
                        <div className={s.middleImage}>
                            <img src={device_img}/>
                        </div>
                        <div id="txtSubject" className={s.subject}>{this.state.labels.txtSubject}</div>
                        <div id="txtDescription" className={s.description}>{this.state.labels.txtDescription}</div>
                    </div>
                </div>
                <div className={s.footer}>
                    <div className={s.footerText}>Eligma Ltd. © {new Date().getFullYear()}</div>
                </div>
            </div>
        );
    }
}


export default withRouter(WrongDevice);
