import React, {Fragment} from "react";
export const SLOVENIAN = {
    LANG_CODE: "sl",
    GOCRYPTO: false,
    CURRENCY:{
        symbol: '€',
        name: 'EUR',
        id: 0,
        minPaymentAmount: 0.01,
    },
    LOGIN: {
        username: "Uporabniško Ime",
        password: "Geslo",
        usernameEmptyError: "Vnesite uporabniško ime",
        passwordEmptyError: "Vnesite geslo",
        login: "PRIJAVA",
        notAuthorized: "Ni dovoljeno",
        hidePassword: "Skrij",
        showPassword: "Prikaži",
        invalidCredentials: "Napačno uporabniško ime ali geslo",

    },
    HEADER: {
        help: "POMOČ",
        callAgent: "Pokliči agenta",
        agentNumber: "+386 51 647 071"
    },
    SIDEBAR: {
        logout: "Odjava",
        newPayment: 'Novo plačilo',
        archive: "Arhiv plačil",
        billing: "Obračun",
        faq: "FAQ"
    },
    ARCHIVE: {
        overviewTitle: "Arhiv plačil",
        refreshLabel: "OSVEŽI",
        exportLabel: "IZVOZI",
        tableHeaders: {
            ID: "ID",
            lastUpdatedTime: "ČAS",
            lastUpdatedDate: "DATUM",
            lastUpdated: "ČAS",
            amount: "VREDNOST",
            combinedAmount: "VREDNOST",
            cryptoCurrency: "VREDNOST KRIPTO",
            cryptoAmount: "VREDNOST KRIPTO",
            print: "NATISNI",
            status: "STATUS",
            referenceNumber: "REFERENCA"
        },
        printLabel: "Print",
        print: {
            amountLabel: "Znesek v valuti",
            transactionInfoLabel: "Informacije o transakciji",
            dateTimeLabel: "Datum in čas",
        }
    },
    PAYMENT: {
        stepLabel: "korak",
        fromLabel: "od",
        createPayment: "Ustvari plačilo",
        paymentReady: "Pripravljeno plačilo",
        paymentFinished: "Zaključek plačila",
        amount: "Znesek plačila",
        amountErrorLow: "Vnešen znesek je prenizek",
        referenceNumberLabel: "Referenčna številka",
        createPaymentButton: "Nadaljuj na plačilo",
        cancelPaymentButton: "prekliči plačilo",
        qrCodeReady: "Koda pripravljena za skeniranje",
        resetPaymentButton: "dokončaj",
        paymentStatus: {
            CRYPTO_PAYMENT_STATUS_OPENED: "ODPRTO",
            CRYPTO_PAYMENT_STATUS_IN_PAYMENT: "V TEKU",
            CRYPTO_PAYMENT_STATUS_PAID: "USPEŠNO",
            CRYPTO_PAYMENT_STATUS_PROCESSING: "OBRAVNAVATI",
            CRYPTO_PAYMENT_STATUS_AUTO_CLOSED: "POTEKLO",
            CRYPTO_PAYMENT_STATUS_FAILED: "NEUSPEŠNO",
            CRYPTO_PAYMENT_STATUS_NOT_VALID: "NEVELJAVNO",
            CRYPTO_PAYMENT_STATUS_REVERTED: "REVERTED"
        },
        paymentStatusLabel: {
            CRYPTO_PAYMENT_STATUS_OPENED: "Koda pripravljena za skeniranje",
            CRYPTO_PAYMENT_STATUS_PAID: "Plačilo uspešno",
            CRYPTO_PAYMENT_STATUS_AUTO_CLOSED: "Čas za plačilo je potekel"
        },
        currencyNotice: ""
    },
    EXPORT: {
        exportTransactionsLabel: "Izvozi plačila",
        timeRangeLabel: "Časovni okvir",
        fileTypeLabel: "Tip datoteke",
        exportLabel: "IZVOZI"
    },

    REPORT: {
        dailyReport: {
            title: "Obračun plačil",
            exportLabel: "IZVOZI",
            print: "NATISNI",
            noTransactions: "Brez transakcij",
            currencyLabel: "vrednost",
            totalLabel: "SKUPNA VSOTA",
            back: "NAZAJ",
            amountLabel: "VSOTA"
        }
    },
    FAQ: {
        title: "Pogosto zastavljena vprašanja",
        QA: [
            {
                question: "Kako ustvarim plačilo z Elipayjem?",
                answer: <Fragment>
                    <p>V glavnem meniju izberite »Novo plačilo« ter v ustrezno vnosno polje s pomočjo tipkovnice ali zaslona na dotik vpišite znesek za plačilo.Če želite, lahko dodate tudi referenčno številko plačila.</p>
                    <p>Kliknite »Ustvari plačilo« in ustvarila se bo QR-koda. Kupca prosite, da poskenira to QR-kodo oziroma QR-kodo na nalepki pri blagajni (kadar kupci ne morejo dostopati do zaslona blagajne).</p>
                    <p>Ko  bo  kupec  s  postopkom  zaključil,  se  bo  na  zaslonu  pojavila  zelena  puščica  z  napisom  »Plačilouspešno«. To pomeni, da so bila sredstva prejeta in da lahko izdate račun.</p>
                    <p>Lahko kliknete tudi »Arhiv« in si v računovodske namene natisnete potrdilo. </p>
                </Fragment>
            },
            {
                question: "Kaj pomeni obvestilo »Čas za plačilo je potekel?«",
                answer: <Fragment>
                    <p>Ko se ustvari plačilo, se na vašem zaslonu prikaže QR koda s časovnikom, kupec pa ima 3 minute časa,da zaključi s plačilom. Nato čas za plačilopoteče in treba je ustvariti novo plačilo.</p>
                </Fragment>
            },
            {
                question: "Vnesel sem napačen znesek za plačilo, QR-koda pa se je že ustvarila. Kaj zdaj?",
                answer: <Fragment>
                    <p>Brez skrbi! Pritisnite le na gumb »Prekličiplačilo« in ponovite postopek.</p>
                </Fragment>
            },
            {
                question: "Kje si lahko ogledam in izvozim zgodovino plačil?",
                answer: <Fragment>
                    <p>V  glavnem  meniju  kliknite  »Arhiv«;  tam  lahko  z  drsnikom  pregledujete  celotno  zgodovino  plačil.Rezultate lahko tudi filtrirate po datumih in statusih plačila. V računovodske namene lahko Arhiv tudi izvozite v formatu CSV ali PDF.</p>
                </Fragment>
            },
            {
                question: "Ali lahko ustvarim dnevni obračunplačil?",
                answer: <Fragment>
                    <p>Seveda!  V  glavnem  meniju  kliknite  »Obračun plačil«;  tam  lahko  natisnete  dnevni  obračun plačil  v različnih valutah.</p>
                </Fragment>
            },
            {
                question: "Katere kriptovalute podpira Elipay?",
                answer: <Fragment>
                    <p>Elipay  omogoča plačila  z  valutami  bitcoin,  bitcoin  cash,  ether  in  ELI-žeton,  vendar  znanja  o  njih  ne potrebujete. Ko bo uporabnik poskeniral QR-kodo, bo v mobilni aplikaciji izbral valuto za plačilo, za vas pa je postopek vedno enak. </p>
                    <p>Poleg  plačil  s  kriptovalutami  Elipay  v  nekaterih  državah  omogoča  tudi  plačilo  z  uporabo  predhodno naložene evrske vrednosti.</p>
                </Fragment>
            },
            {
                question: "S katerimi denarnicami lahko plačujejo moji kupci?",
                answer: <Fragment>
                    <p>Vaši kupci lahko uporabijo aplikacijo Elipay ali aplikacijo Bitcoin.com. Ne glede na izbrano denarnico je postopek za vas vedno enak.</p>
                </Fragment>
            },
            {
                question: "Ali je možno tudi plačevanje z običajnimi valutami (torej takšnimi, ki niso kriptovalute)?",
                answer: <Fragment>
                    <p>Poleg  plačil  s  kriptovalutami  Elipay  v  nekaterih  državah  omogoča  tudi  plačilo  z  uporabo  predhodno naložene evrske vrednosti. </p>
                </Fragment>
            },
            {
                question: "Kupec zahteva vračilo kupnine.",
                answer: <Fragment>
                    <p>Po  uspešno  izvedenem  plačilu vračilo  kupnine  prek  sistema  Elipay  ni  možno;  vračilo  mora  opraviti trgovec.</p>
                </Fragment>
            },
            {
                question: "Pri ustvarjanju plačila ali uporabi POS-a Elipay imam težave.",
                answer: <Fragment>
                    <p>Prosimo,  da  kontaktirate  našega  zastopnika  na  številko  051  647  071.  Na  voljo  smo  vam  tudi  na <a href="mailto:podpora@elipay.com">podpora@elipay.com</a>. </p>
                </Fragment>
            }
        ]
    },
};

