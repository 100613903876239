import React from "react";
import moment from "moment";

import s from "./confirm.module.css";
import logo_img from "./img/elly_logo.svg"
import locked_img from "./img/locked_img.svg"

class ChangePasswordLocked extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            labels: {}
        };

        const TRANSLATIONS = {
            'en': {
                'txtSubject': 'Your Elly account is now locked',
                'txtDescription': 'We will contact you by e-mail as soon as possible!',
                'txtFooter1': 'If you made a mistake, please e-mail us at ',
                'txtFooter2': ' to reactivate your account.',

                'emailSubject': 'Account reactivation',
                'emailBody': 'Please reactivate my account as it was locked due to my error.'
            },
            'sl': {
                'txtSubject': 'Tvoj Elly račun je zdaj zaklenjen',
                'txtDescription': 'Kar najhitreje se ti oglasimo po e-pošti!',
                'txtFooter1': 'Če si geslo spremenil sam, nam pošlji e-pošto na ',
                'txtFooter2': '',

                'emailSubject': 'Ponovna aktivacija računa',
                'emailBody': 'Prosim za ponovno aktivacijo računa. Do zaklepa je prišlo po pomoti.'
            }
        };
        const params = this.get_query_params();
        let language = params['lang'] || window.navigator.userLanguage || window.navigator.language;
        this.state.labels = (language in TRANSLATIONS) ? TRANSLATIONS[language] : TRANSLATIONS['en'];

        this.get_query_params = this.get_query_params.bind(this);
    }

    get_query_params(){
        if (window.location.search !== '' && window.location.search.indexOf('?') >= 0) {
            return window.location.search
                .split('?')[1]
                .split('&')
                .map(x => {
                    var p = x.split('=');
                    var r = {};
                    r[unescape(p.shift())] = unescape(p.join('='));
                    return r;
                }).reduce(function (result, pair) {
                    return {...result, ...pair}
                }, {});
        }
        return null;
    }

    render() {
        return (
            <div className={s.body}>
                <div className={s["upper-content"]}>
                    <a href="https://www.elly.com" className={s["upper-link-left"]}>
                        <img src={logo_img} alt="Logo" className={s["upper-image"]}/>
                    </a>
                    <a href="https://www.elly.com" className={s["upper-link-right"]}>elly.com</a>
                </div>

                <div className={s['middle-content']}>
                    <div className={s['middle-child']}>
                        <div className={s["middle-image"]}>
                            <img src={locked_img}/>
                        </div>
                        <div id="txtSubject" className={s["subject"]}>{this.state.labels.txtSubject}</div>
                        <div id="txtDescription" className={s["description"]}>{this.state.labels.txtDescription}</div>
                    </div>
                    <div className={s['middle-child']}>
                        <hr />
                        {this.state.labels.txtFooter1}
                        <a href={"mailto:support@elly.com?subject="+this.state.labels.emailSubject+"&body="+this.state.labels.emailBody}>support@elly.com</a>
                        {this.state.labels.txtFooter2}
                    </div>
                </div>
                <div className={s["footer"]}>
                    <div className={s["footer-text"]}>Eligma Ltd. © {moment().format("YYYY")}</div>
                </div>
            </div>
        );
    };

}

export default ChangePasswordLocked;
