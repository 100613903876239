import React from 'react';
import qs from 'query-string';
import {SLOVENIAN} from '../../translation/sl';
import {Button, Col, Container, Input, Row} from "reactstrap";
import s from './passwordReset.module.css';
import {apiClient} from "../../WebApi/util";
import cx from 'classnames';
import {API_URL} from "../../config";

class PasswordReset extends React.Component {

    state={
        password: "",
        confirmPassword: "",
        token: "",
        lang: "",
        error: null
    };

    componentDidMount() {

        const values = qs.parse(window.location.search, { ignoreQueryPrefix: true });

        this.setState({ token: values.token, lang:values.lang });

    }

    componentDidUpdate() {
    }

    validatePasswords = () => {
        if(this.state.password.length < 6){
            this.setState({ error: "Minimum 6 characters."});
            return;
        }

        if(!this.state.password.match(/([0-9]+[a-zA-Z]+|[a-zA-Z]+[0-9]+)[0-9a-zA-Z]*/)){
            this.setState({error: "Must contain letters and numbers"});
            return;
        }

        console.log(this.state);

        if(this.state.password.valueOf() != this.state.confirmPassword.valueOf()){
            this.setState({error: "Passwords do not match"});
            return;
        }

        this.setState({ error: null });

    };

    onChange = (e, key) => {
        let value = this.state[key];
        value = e.target.value;
        this.setState({ [key]:value },() =>this.validatePasswords());

    };

    onSubmit = e => {
        e.preventDefault();

        apiClient.post(`${API_URL}/password/new/${this.state.token}`, {
            'new_password':this.state.password,
            'confirm_password': this.state.confirmPassword
        }).then(response => {
            if(this.state.lang == 'sl'){
                window.location = 'https://elipay.com/sl/ponastavitev-gesla-uspesna';
                return;
            }
            window.location = 'https://elipay.com/en/password-reset-success';
        }).catch(error => {
            let errorData = JSON.parse(JSON.stringify(error)).response.data.message;
            try{
                errorData = JSON.parse(errorData);
                if (errorData[0] && errorData[0].reason) errorData = errorData[0].reason;
            }catch(e){

            }

            this.setState({ error: errorData});
        });

    };


    render() {

        return (
            <Container className="pt-75 pb-75 text-center">
                <Row>
                    <Col sm="12" className="d-flex justify-content-center">
                        <div className={cx(s.card, "card")}>
                            <form onSubmit={(e) => this.onSubmit(e)}>
                                <div className={s.title}>{SLOVENIAN.LOGIN.RESET_CONFIRM}</div>
                                <div className={s.field}>
                                    <div className={cx(s.label, "text-left")}>{SLOVENIAN.LOGIN.NEW_PASSWORD}</div>
                                    <Input type="password" onChange={(e) => this.onChange(e, "password")} styleName="input" value={this.state.password} placeholder={SLOVENIAN.LOGIN.TYPE_HERE} />
                                </div>
                                <div className={s.field}>
                                    <div className={cx(s.label, "text-left")}>{SLOVENIAN.LOGIN.REPEAT_NEW_PASSWORD}</div>
                                    <Input type="password" onChange={(e) => this.onChange(e, "confirmPassword")} styleName="input" value={this.state.confirmPassword} placeholder={SLOVENIAN.LOGIN.TYPE_HERE} />
                                </div>

                                <div className={s.error}>{this.state.error ? this.state.error : ""}</div>

                                <div className="d-flex justify-content-center">

                                    <Button className={s.button}>{SLOVENIAN.LOGIN.RESET_CONFIRM}</Button>
                                </div>
                            </form>
                        </div>
                    </Col>
                </Row>
            </Container>
        );
    };

}

export default PasswordReset;
