import React from "react";
import {Route} from "react-router";
import UnsubscribeSuccessful from "./components/UnsubscribeSuccessful/UnsubscribeSuccessful";
import {BrowserRouter} from "react-router-dom";
import WrongDevice from "./components/WrongDevice/WrongDevice";
import PasswordReset from "./components/PasswordReset/PasswordReset";
import ChangePasswordConfirm from "./components/PasswordReset/Confirm";
import ChangePasswordLocked from "./components/PasswordReset/Locked";
import ChangePasswordMisclick from "./components/PasswordReset/Misclick";
import Registration from "./components/Registration/Registration";
import Cancel from "./components/Registration/Cancel";

export const routes = {
    confirm:{path: "/registration/confirm/"},
    cancel:{path: "/registration/cancel/"},
    reset:{path: "/reset/confirm/"},
    kycWrongDevice: {path: "/kyc/wrong-device/"},
    registrationWrongDevice: {path: "/confirm-registration/wrong-device/"},
    passwordWrongDevice: {path: "/reset-password/wrong-device/"},
    changePasswordConfirm: {path: "/change-password/confirm/"},
    changePasswordLocked: {path: "/change-password/locked/"},
    changePasswordMisclick: {path: "/change-password/misclick/"},
    unsubscribeSuccessfull: {path: "/unsubscribe/"}
};

class AppRouter extends React.Component {

    render() {
          return <>
                    <BrowserRouter>
                        <Route exact path={routes.unsubscribeSuccessfull.path} component={UnsubscribeSuccessful} />
                        <Route exact path={routes.registrationWrongDevice.path} component={WrongDevice} />
                        <Route exact path={routes.reset.path}  component={PasswordReset} />
                        <Route exact path={routes.changePasswordConfirm.path} component={ChangePasswordConfirm} />
                        <Route exact path={routes.changePasswordLocked.path} component={ChangePasswordLocked} />
                        <Route exact path={routes.changePasswordMisclick.path} component={ChangePasswordMisclick} />
                        <Route exact path={routes.confirm.path} component={Registration} />
                        <Route exact path={routes.cancel.path} component={Cancel} />
                        <Route exact path={routes.kycWrongDevice.path} component={WrongDevice} />
                        <Route exact path={routes.passwordWrongDevice.path} component={WrongDevice} />
                    </BrowserRouter>

              </>
    }
}

export default AppRouter;
