import React from 'react';
import {withRouter} from 'react-router-dom';
import qs from 'query-string';
import {apiClient} from "../../WebApi/util";
import {Col, Container, Row} from "reactstrap";
import {getSafeDeep} from "../../Utils/state";
import {API_URL} from "../../config";

class Registration extends React.Component {
    state = {
        errorMessage: "Please wait..."
    };

    componentDidMount() {
        const values = qs.parse(window.location.search, { ignoreQueryPrefix: true });

        /*if(values.token="test"){
            if(values.lang == 'sl'){
                window.location.replace('https://elipay.com/sl/ponastavitev-gesla-uspesna')
            }else window.location.replace('https://elipay.com/en/activation-success');
        }*/
        apiClient.post(`/register/confirm/${values.token}`).then(response => {
            if(response.data.success){
                if(values.lang == 'sl'){
                    window.location = 'https://elipay.com/sl/aktivacija-uspesna';
                }else window.location = 'https://elipay.com/en/activation-success';
            }else{
                this.setState({errorMessage: "Invalid token"});
            }

        }).catch(error => {
            this.setState({errorMessage: "Invalid token"});
        });
    }

    componentDidUpdate() {
    }

    render() {

        return (
            <Container className="pt-75 w-100 pb-75 text-center">
                <Row>
                    <Col sm="12">
                        {this.state.errorMessage && <div>{this.state.errorMessage}</div>}
                    </Col>
                </Row>
            </Container>

        )
            ;
    };

}

export default withRouter(Registration);
