import React from "react";
import moment from "moment";

import s from "./confirm.module.css";
import logo_img from "./img/elly_logo.svg"
import misclick_img from "./img/password_ok_img.svg"

class ChangePasswordMisclick extends React.Component {    constructor(props){
    super(props);

    this.state = {
        labels: {}
    };

    const TRANSLATIONS = {
        'en': {
            'txtSubject1': 'Hey, no worries!',
            'txtSubject2': 'Keep having fun with Elly!',
            'txtButton': 'Open Elly app'
        },
        'sl': {
            'txtSubject1': 'Nič hudega! Veliko zabave z Ellyjem še naprej!',
            'txtSubject2': '',
            'txtButton': 'Odpri aplikacijo Elly'
        }
    };
    const params = this.get_query_params();
    let language = (params && params['lang']) || window.navigator.userLanguage || window.navigator.language;
    this.state.labels = (language in TRANSLATIONS) ? TRANSLATIONS[language] : TRANSLATIONS['en'];

    this.get_query_params = this.get_query_params.bind(this);
}

    get_query_params(){
        if (window.location.search !== '' && window.location.search.indexOf('?') >= 0) {
            return window.location.search
                .split('?')[1]
                .split('&')
                .map(x => {
                    var p = x.split('=');
                    var r = {};
                    r[unescape(p.shift())] = unescape(p.join('='));
                    return r;
                }).reduce(function (result, pair) {
                    return {...result, ...pair}
                }, {});
        }
        return null;
    }

    render() {
        return (
            <div className={s.body}>
                <div className={s["upper-content"]}>
                    <a href="https://www.elly.com" className={s["upper-link-left"]}>
                        <img src={logo_img} alt="Logo" className={s["upper-image"]}/>
                    </a>
                    <a href="https://www.elly.com" className={s["upper-link-right"]}>elly.com</a>
                </div>

                <div className={s['middle-content']}>
                    <div className={s['middle-child']}>
                        <div className={s["middle-image"]}>
                            <img src={misclick_img}/>
                        </div>
                        <div id="txtSubject" className={s["subject"]}><p>{this.state.labels.txtSubject1}</p><p>{this.state.labels.txtSubject2}</p></div>
                        <div className={s["button-container"]}>
                        <a href="https://elipay.app.link/NlZx6NzE4S"
                           className={s["a-button"]}>{this.state.labels.txtButton}</a>
                        </div>
                    </div>
                </div>
                <div className={s["footer"]}>
                    <div className={s["footer-text"]}>Eligma Ltd. © {moment().format("YYYY")}</div>
                </div>
            </div>
        );
    };

}

export default ChangePasswordMisclick;
