import React from 'react';
import qs from 'query-string'
import {withRouter} from 'react-router-dom';
import s from "./cancel.module.css";
import logo_img from "./elly_logo.svg";
import device_img from "./device_img.svg";
import auth from "../../WebApi/auth";

const langs = {
    'en': 'You have successfully canceled your registration',
    'es': 'Has cancelado tu registro con éxito',
    'sl': 'Uspešno preklicana registracija'
};

class Cancel extends React.Component {

    state = {
        text: 'Loading..'
    };

    async componentDidMount() {
        const { token, lang } = qs.parse(window.location.search, { ignoreQueryPrefix: true });
        try{
            if(!token || !lang) throw new Error();
            await auth.cancel(token);
            this.setState({ text: langs[lang] || langs['en'] })
        }catch(e){
            this.setState({ text: 'Something went wrong...' })
        }
    }

    render() {

        return (
            <div className={s.body}>
                <div className={s.upperContent}>
                    <a href="https://www.elly.com" className={s.upperLinkLeft}>
                        <img src={logo_img} alt="Logo" className={s.upperImage}/>
                    </a>
                    <a href="https://www.elly.com" className={s.upperLinkRight}>elly.com</a>
                </div>

                <div className={s.middleContent}>
                    <div className={s.middleChild}>
                        <div className={s.middleImage}>
                            <img src={device_img}/>
                        </div>
                        <div id="txtSubject" className={s.subject}>{this.state.text}</div>
                    </div>
                </div>
                <div className={s.footer}>
                    <div className={s.footerText}>Eligma Ltd. © {new Date().getFullYear()}</div>
                </div>
            </div>
        );
    }
}


export default withRouter(Cancel);
