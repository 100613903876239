import React from "react";
import moment from "moment"

import s from "./confirm.module.css";
import logo_img from "./img/elly_logo.svg";
import device_img from "./img/unauthorized_change_img.svg";
import credentials from "../../WebApi/credentials"

class ChangePasswordConfirm extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            labels: {}
        };

        const TRANSLATIONS = {
            'en': {
                'txtSubject': 'Are you sure that it was not you who changed your password?',
                'txtButtonOk': 'Yes, I’m sure',
                'txtButtonCancel': 'Cancel'
            },
            'sl': {
                'txtSubject': 'Si prepričan, da je geslo spremenil nekdo drug?',
                'txtButtonOk': 'Da',
                'txtButtonCancel': 'Prekliči'
            }
        };
        const params = this.get_query_params();
        let language = params && params['lang'] || window.navigator.userLanguage || window.navigator.language;
        this.state.labels = (language in TRANSLATIONS) ? TRANSLATIONS[language] : TRANSLATIONS['en'];

        this.get_query_params = this.get_query_params.bind(this);
        this.lockAccount = this.lockAccount.bind(this);
        this.misclick = this.misclick.bind(this);
    }

    get_query_params(){
        if (window.location.search !== '' && window.location.search.indexOf('?') >= 0) {
            return window.location.search
                .split('?')[1]
                .split('&')
                .map(x => {
                    var p = x.split('=');
                    var r = {};
                    r[unescape(p.shift())] = unescape(p.join('='));
                    return r;
                }).reduce(function (result, pair) {
                    return {...result, ...pair}
                }, {});
        }
        return null;
    }

    lockAccount(e){
        e.preventDefault();
        const params = this.get_query_params();
        if(params){
            if ('token' in params) {
                credentials.lock_user(params['token'])
                .then(function (response) {
                    var r = '';
                    try {
                        r = response.json();
                    } catch (e) {
                    }
                    return r;
                })
                .then(function () {
                    window.location.pathname = '/change-password/locked';
                })
                .catch(function (error) {
                    console.error(error);
                });
            }
        }
    }

    misclick(e) {
        e.preventDefault();
        window.location.pathname = '/change-password/misclick';
    }

    render() {
        return (
            <div className={s.body}>
                <div className={s["upper-content"]}>
                    <a href="https://www.elly.com" className={s["upper-link-left"]}>
                        <img src={logo_img} alt="Logo" className={s["upper-image"]}/>
                    </a>
                    <a href="https://www.elly.com" className={s["upper-link-right"]}>elly.com</a>
                </div>

                <div className={s['middle-content']}>
                    <div className={s['middle-child']}>
                        <div className={s["middle-image"]}>
                            <img src={device_img}/>
                        </div>
                        <div id="txtSubject" className={s["subject"]}>{this.state.labels.txtSubject}</div>
                        <div className={s["button-container"]}>
                            <a href="/change-password/locked"
                               onClick={this.lockAccount}
                               className={s["a-button-white"]}>{this.state.labels.txtButtonOk}</a>
                            <a href="/change-password/misclick"
                               onClick={this.misclick}
                               className={s["a-button-white"]}>{this.state.labels.txtButtonCancel}</a>
                        </div>
                    </div>
                </div>
                <div className={s["footer"]}>
                    <div className={s["footer-text"]}>Eligma Ltd. © {moment().format("YYYY")}</div>
                </div>
            </div>
        );
    };
}

export default ChangePasswordConfirm;
