// @flow
import axios, {AxiosInstance} from "axios";
import {API_URL} from "../config";
import {getSafeDeep} from "../Utils/state";

export let apiClient: AxiosInstance = axios.create({
    baseURL: API_URL,
    withCredentials: true,
    xsrfCookieName: 'csrftoken',
    xsrfHeaderName: 'X-CSRFToken'
});

export let apiClientV3: AxiosInstance = axios.create({
    baseURL: API_URL.replace("v2","v3"),
    withCredentials: true,
    xsrfCookieName: 'csrftoken',
    xsrfHeaderName: 'X-CSRFToken'
});


apiClient.interceptors.request.use(
    config => {
        const language = getSafeDeep(JSON.parse(localStorage.getItem('Elipay-POS')), 'language.code', 'en');
        config.headers['Accept-Language'] = language;
        return config;
    },
    error => Promise.reject(error)
);

apiClientV3.interceptors.request.use(
    config => {
        const language = getSafeDeep(JSON.parse(localStorage.getItem('Elipay-POS')), 'language.code', 'en');
        config.headers['Accept-Language'] = language;
        return config;
    },
    error => Promise.reject(error)
);

